import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";




export interface LastActivities {
  lastActivities: Array<string>,

}

export interface LastActivitiesInfo {
  errors: unknown;
  lastActivitiesList: LastActivities;

}

@Module
export default class LastActivitiesModule extends VuexModule implements LastActivitiesInfo {
  errors : unknown;
  lastActivitiesList = {} as LastActivities;


  get getLastActivities(): LastActivities {
    return this.lastActivitiesList;
  }



  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_LAST_ACTIVITIES](lastActivities) {
    this.lastActivitiesList = lastActivities;
  }

  @Action
  [Actions.LOAD_LAST_ACTIVITIES]() {
    
    ApiService.setHeader();
      ApiService.get("dashboard/indicators/lastactivities")
        .then(({ data }) => {
          if(data.data)
            this.context.commit(Mutations.SET_LAST_ACTIVITIES, data.data)
          
        })
        .catch(({ data }) => {
          console.log(data)
        });

  }

}
