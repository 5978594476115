import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface InDebt {
  id: string;
  documentName: string;
  documentNumber: string;
  docAbrvName: string;
  date: string;
  dueDate: string;
  status: string;
  daysReleased: number;
  total: number;
  totalpaid: number;
  totalindebt: number;
  inDebteSum: number

}

export interface InDebtInfo {
  errors: unknown;
  inDebtList: InDebt;

}

@Module
export default class InDebtModule extends VuexModule implements InDebtInfo {
  errors : unknown;
  inDebtList = {} as InDebt;


  get getInDebt(): InDebt {
    return this.inDebtList;
  }
  get getIndebtSum(): number {
    if(!this.inDebtList)
      return 0;
    return this.inDebtList.inDebteSum;
  }

  @Mutation
  [Mutations.SET_INDEBT](inDebtList) {
    this.inDebtList = inDebtList;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_INDEBT_SUM](inDebt) {
    
    this.inDebtList.inDebteSum = inDebt.map(item => parseFloat(item.totalIndebt)).reduce((prev, curr) => prev + curr, 0).toFixed(2);;

  }


  @Action
  [Actions.LOAD_INDEBT]() {
    ApiService.setHeader();
      ApiService.get("indebt")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_INDEBT, data.data)
          this.context.commit(Mutations.SET_INDEBT_SUM, data.data)
        })
        .catch(({ data }) => {
          console.log(data)
        });

  }

}
