enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  LOAD_INVOICES = "loadInvoices",
  LOAD_PRODUCTS = "loadProducts",
  LOAD_DASH_INVOICES = "loadDashInvoices",
  LOAD_USER_ME = 'loadUserMe',
  LOAD_OCCURENCES = 'loadOccurences',
  STORE_OCCURENCE = 'storeOccurence',
  LOAD_INDEBT = 'loadInDebt',
  LOAD_LAST_ACTIVITIES = 'loadLastActivities',
  LOAD_NOTIFICATIONS = 'loadNotifications',
  UPDATE_NOTIFICATIONS_STATUS = 'updateNotificationsStatus',
  REFRESH = 'refresh',
  RESTORE_TOKEN = 'restoreToken',
  GET_OBJ_TOKEN = 'getObjToken',
  REFRESH_TOKEN = 'refreshToken',
  LOGIN_AS_CLIENT = 'loginAsClient',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_INVOICES = 'setInvoices',
  SET_PRODUCTS = 'setProducts',
  SET_DASH_INVOICES = "setDashInvoices",
  SET_OCCURENCES = "setOccurences",
  SET_INDEBT = 'setIndebt',
  SET_ACTIVE_ORDERS= 'setActiveOrders',
  SET_INDEBT_SUM = 'setInDebtSum',
  SET_OPEN_OCCURENCES = 'setOpenOccurences',
  SET_LAST_ACTIVITIES = 'setLastActivities',
  SET_NOTIFICATIONS = 'setNotifications',
  SET_USER_RELOADED = 'setUserReloaded',

}

export { Actions, Mutations };
