import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import { date } from "yup/lib/locale";

export interface Invoice {
  id: string;
  documentName: string;
  documentNumber: string;
  docAbrvName: string;
  date: string;
  totalWithoutTax: string;
  discountTotal: string;
  taxTotal: string;
  total: string;
  lines: Array<string>;

}

export interface InvoiceInfo {
  errors: unknown;
  invoices: Array<Invoice>;

}

@Module
export default class InvoicesModule extends VuexModule implements InvoiceInfo {
  errors : unknown;
  invoices : any;
  isAuthenticated = !!JwtService.getToken();

  get getAvailabelOccInvoices() {
    //if invoices not availabe
    if(!this.getInvoices) {
      return
    }

    const date = new Date();
    const twoWeeksAgoDate = new Date(date.setDate(date.getDate() - 15))

    return this.invoices.filter(function(item) {
      const parts_of_date = item.date.split("/");

      const output = new Date(+parts_of_date[2], parts_of_date[1] - 1, +parts_of_date[0]);
      
      return item.occAvailable == true && output >= twoWeeksAgoDate;
    })
  }

  get getInvoices(): Invoice {
    return this.invoices;
  }

  @Mutation
  [Mutations.SET_INVOICES](invoices) {
    this.invoices = invoices;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }






  @Action
  [Actions.LOAD_INVOICES]() {
    ApiService.setHeader();
      ApiService.get("invoices")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_INVOICES, data.data)
        })
        .catch(({ data }) => {
          console.log(data)
        });

  }

}
