import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import router from "@/router/clean";



export interface DashboardIndicators{
  encAbertas: number;
  incAbertas: number;
  incFechadas: number;
  fat12Meses: number;
  porRegularizar: number,
  taxaResposta: number,
}

export interface DashboardInfo {
  errors: unknown;
  dashboardInvoices: DashboardIndicators;

}

@Module
export default class DashboardModule extends VuexModule implements DashboardInfo {
  errors : unknown;
  dashboardInvoices = {} as DashboardIndicators;


  get getDashboardInvoices(): DashboardIndicators {
    return this.dashboardInvoices;
  }

  @Mutation
  [Mutations.SET_DASH_INVOICES](dashboardInvoices: DashboardIndicators) {
    this.dashboardInvoices = dashboardInvoices;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }


  @Action
  [Actions.REFRESH]() {
    //load user
    this.context.dispatch(Actions.RESTORE_TOKEN)
    
  }


  @Action
  [Actions.LOAD_DASH_INVOICES]() {
    
    ApiService.setHeader();
      ApiService.get("/Cliente/DashBoardIndicators")
        .then(({ data }) => {
          if(data.data)
            this.context.commit(Mutations.SET_DASH_INVOICES, data.data[0])
          
        })
        .catch(({ data }) => {
          console.log(data)
        });
  }


  
}
