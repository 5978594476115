import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import store from "..";

export interface Notifications {  
  notifications : Set<string>
}

export interface NotificationsInfo {
  errors: unknown;
  notifications: Notifications;

}

@Module
export default class NotificationsModule extends VuexModule implements NotificationsInfo {
  errors : unknown;
  notifications : any;


  get getNotifications(): Notifications {
    return this.notifications;
  }

  @Mutation
  [Mutations.SET_NOTIFICATIONS](notifications) {
    //Get notifications stored on session storage

    let existingNotifications = [] as any

    //if notifications stored for this user 
    if(sessionStorage.getItem('notifications') && sessionStorage.getItem('user_id') == store.getters.currentUser.customercode && sessionStorage.getItem('notifications')!.length > 2) {

      existingNotifications = JSON.parse(sessionStorage.getItem('notifications')!);
    }

    const uniqueNotifications = new Set();

    notifications.forEach(item => uniqueNotifications.add(item))
    existingNotifications.forEach(item => uniqueNotifications.add(item))

    

    this.notifications = uniqueNotifications;
    sessionStorage.setItem('user_id', store.getters.currentUser.customercode)    
    sessionStorage.setItem('notifications', JSON.stringify(Array.from(this.notifications)))
    
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }


  @Action
  [Actions.UPDATE_NOTIFICATIONS_STATUS](requestBody) {

    ApiService.patch("notifications/syncrp", requestBody)
    .then(() => {
      console.log("Notifications marked sucessfully")
    })
    .catch(({ data }) => {
      console.log(data)
    }); 
    
  }


  @Action
  [Actions.LOAD_NOTIFICATIONS]() {
    ApiService.setHeader();
      ApiService.get("notifications")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_NOTIFICATIONS, data.data)        
        })
        .catch(({ data }) => {
          console.log(data)
        });

  }

}
