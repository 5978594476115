
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "app",
    created () {
    window.addEventListener('beforeunload', this.onLeave)
  },
  methods : {
    onLeave() {
      console.log("leaving")      
    }


  },
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      store.dispatch(Actions.REFRESH)
      

      nextTick(() => {
        initializeComponents();
      });
    });
    return{
      store,
    }
  },
});
