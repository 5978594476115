import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Occurence {
  totalOccurences: number;
  processingOccurrences: number;
  resolvingOccurences:number;
  closedOccurences: number;
  occurencesWithResponsePercent: number;
  occurences: Array<any>;
  prodsQuantity: number;

}

export interface OccurenceInfo {
  errors: unknown;
  occurence: Occurence;
}

@Module
export default class OccurencesModule extends VuexModule implements OccurenceInfo {
  errors : unknown;
  occurence = {} as Occurence;



  get getOccurencesList(): any {
    return this.occurence.occurences;
  }
  get getOccurencesIndicators(): any {
    return {totalOccurences : this.occurence.totalOccurences,
            processingOccurrences : this.occurence.processingOccurrences,
            resolvingOccurences : this.occurence.resolvingOccurences,
            closedOccurences : this.occurence.closedOccurences,
            occurencesWithResponsePercent : this.occurence.occurencesWithResponsePercent }
  }

  @Mutation
  [Mutations.SET_OCCURENCES](occurences) {
    this.occurence = occurences;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }


  @Action
  [Actions.LOAD_OCCURENCES]() {
    ApiService.setHeader();
      ApiService.get("occurences")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_OPEN_OCCURENCES, data.data)
          this.context.commit(Mutations.SET_OCCURENCES, data.data)
        })
        .catch(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, data)
          return data
        });
  }

  @Action
  [Actions.STORE_OCCURENCE](requestBody) {

    ApiService.setHeader();
      ApiService.post("occurences", requestBody)
        .then(({data}) => {
          this.context.dispatch(Actions.LOAD_OCCURENCES)
        })
        .catch(({ data }) => {
          console.log(data)
        });

  }

}
