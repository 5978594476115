import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Product {
  id: string;
  documentName: string;
  documentNumber: string;
  docAbrvName: string;
  date: string;
  totalWithoutTax: string;
  discountTotal: string;
  taxTotal: string;
  total: string;
  lines: Array<string>;

}

export interface ProductInfo {
  errors: unknown;
  products: Product;
}

@Module
export default class ProductsModule extends VuexModule implements ProductInfo {
  errors : unknown;
  products = {} as Product;



  get getProducts(): Product {
    return this.products;
  }

  @Mutation
  [Mutations.SET_PRODUCTS](products) {
    this.products = products;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }


  @Action
  [Actions.LOAD_PRODUCTS](query: string) {
    ApiService.setHeader();
    let queryParams = '?PageSize=999999&inativo=false&Stns=false';
    if(query) {
      queryParams += `${query}`;
    }
      ApiService.get("products", queryParams)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PRODUCTS, data.data)
          return data.data;

        })
        .catch(({ data }) => {
          console.log(data)
        });

  }

}
