import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {AxiosRequestConfig} from "axios";
import store from "..";
import DashboardModule from "./DashboardModule";
import {sha256, sha224} from 'js-sha256';

export interface AuthUSer {
  acessoAreaComercial: boolean;
  acessoAreaFinanceira: boolean;
  email: string;
  nome: string;
  numcl: string;
  expires: string;
  codVendedor: number;
}

export interface User {
  nome: string;
  email: string;
  nif: string;
  numcl: string;
  saldo: string;
  telf1: string;
  telf2: string;
  zona: string;
  morada: string;
  codPostal: string;
  local: number;
  vendedor: Array<any>;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  authUser: AuthUSer;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  authUser = {} as AuthUSer;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get currentAuthUser(): AuthUSer {
    return this.authUser;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Action
  [Actions.GET_OBJ_TOKEN](params) {
    const base64Url = params.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    const tokenObj = JSON.parse(jsonPayload);
    return tokenObj;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;

    this.authUser = data;
    this.errors = [];

    const tokeAuth = data.token;

    const base64Url = tokeAuth.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    const tokenObj = JSON.parse(jsonPayload);

    this.authUser.expires = tokenObj.exp;
    JwtService.saveToken(data.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
    sessionStorage.setItem("user_id", user.numcl);
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_USER_RELOADED](token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    const tokenObj = JSON.parse(jsonPayload);
    this.authUser.numcl = tokenObj.nameid;
    this.authUser.nome = tokenObj.unique_name;
    this.authUser.email = tokenObj.email;
    this.authUser.codVendedor = tokenObj.codVendedor;

    if (tokenObj.type.includes("areaComercial"))
      this.authUser.acessoAreaComercial = true;

    if (tokenObj.type.includes("areaFinanceira"))
      this.authUser.acessoAreaFinanceira = true;

  }

  @Action
  [Actions.RESTORE_TOKEN]() {
    const storedToken = JwtService.getToken();
    if (storedToken) {


      this.context.dispatch("loadUserMe");
      this.context.commit(Mutations.SET_USER_RELOADED, storedToken);

      //SET AUTHUSER EXPIRES
      const base64Url = storedToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
          window
              .atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
      );

      const tokenObj = JSON.parse(jsonPayload);
      this.authUser.expires = (tokenObj.exp).toString();


      return storedToken;
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
      return null;
    }
  }

  @Action
  [Actions.LOGIN](credentials) {
    ApiService.setHeader();
    //credentials.password = sha256(credentials.password);

    return ApiService.post("user/login", credentials)
        .then(({data}) => {
          this.context.commit(Mutations.SET_AUTH, data);
          this.context.dispatch("loadUserMe");

          return 200;
        })
        .catch(({response}) => {
          this.context.commit(Mutations.SET_ERROR, response.data);

          return response.status;
        });
  }

  @Action
  [Actions.LOGIN_AS_CLIENT](credentials) {
    ApiService.setHeader();
    return ApiService.post("/User/Vendedor", credentials)
        .then(({data}) => {
              this.context.commit(Mutations.SET_AUTH, data);
              this.context.dispatch("loadUserMe");
              return 200;
            }
        ).catch(({response}) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          return response.status;
        });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
        .then(({data}) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({response}) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    return ApiService.query("forgot_password", params)
        .then(({data}) => {
          this.context.commit(Mutations.SET_USER, data.data);
        })
        .catch(({response}) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("validate-token").catch(({response}) => {
        console.log("Unauthorized");
        this.context.commit(Mutations.PURGE_AUTH);
      });
    } else {
      console.log("Unauthorized");
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.LOAD_USER_ME]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("cliente/dados")
          .then(({data}) => {
            sessionStorage.setItem("user_id", data[0].numcl);
            this.context.commit(Mutations.SET_USER, data[0]);
          })
          .catch(({response}) => {
            this.context.commit(Mutations.SET_ERROR, response);
          });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.REFRESH_TOKEN]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("User/RefreshToken", {}).then(({data}) => {
            this.context.commit(Mutations.SET_AUTH, data);
          }
      ).catch(({response}) => {
            this.context.commit(Mutations.PURGE_AUTH);
          }
      );
    }
  }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
